import { FastField, Field, Form, FormikProps, withFormik } from "formik";
import { RavenStatic } from "raven-js";
import * as React from "react";
import { v4 as createUuid } from "uuid";
import { hasText } from "../lib/utilities";

const MultiDatePreview = React.lazy(() => import("./MultiDatePreview"));

const endpoint = "https://forms.nationaleventpros.com/event";
const inquirySessionTTL = 1 * 60 * 60 * 1000; // 1 hour

interface StoredData {
  mtime: number;
  value: string;
}

interface Props {
  abort: (error: Error) => void;
  scrollIntoViewIfNeeded: () => void;
  setSubmitted: () => void;

  gtag: Gtag | undefined;
  raven: RavenStatic;

  context?: string;
  event: boolean;
  layout: "2016" | "2019";
  messageInputRef?: React.Ref<HTMLTextAreaElement>;
  minimal: boolean;
  placeholder: string;
  prompt: string;
  suggestions?: { [K in keyof Values]?: string };
}

interface State {
  isRevealed: { dates: boolean; location: boolean; organization: boolean; phone: boolean };
  isUnavailable: { organization: boolean };
}

interface Values {
  context?: string;
  dates?: string;
  email: string;
  inquiryId?: string;
  location?: string;
  message: string;
  name: string;
  organization?: string;
  phone?: string;
  prompt: string;
  receiptVariant?: "time-estimate";
  subscribe?: boolean;
}

const getInquiryId = (raven: RavenStatic): string | undefined => {
  const now = Date.now();

  try {
    const json = localStorage.getItem("inquiryId");
    const previous: StoredData | null = json && JSON.parse(json);

    const id = previous && now - previous.mtime < inquirySessionTTL ? previous.value : createUuid();

    const next: StoredData = { mtime: now, value: id };
    localStorage.setItem("inquiryId", JSON.stringify(next));

    return id;
  } catch (error) {
    raven.captureException(error);

    return undefined;
  }
};

const reportAnalytics = (
  raven: RavenStatic,
  gtag: Gtag | undefined,
  isOrganization: boolean,
): void => {
  try {
    if (gtag && document.location) {
      console.log("Emitting Google AdWords beacon");
      gtag("event", "conversion", {
        send_to: "AW-987727095/FikaCML48H4Q94n-1gM",
        transport_type: "beacon",
      });

      console.log("Emitting Google Analytics beacon");
      gtag("event", "submit", {
        event_category: "Inquiry",
        event_label: isOrganization ? "Organization" : "Individual",
        transport_type: "beacon",
      });
    }
  } catch (error) {
    console.log("Ignoring error", error);
    raven.captureException(error);
  }
};

class ContactFormInner extends React.PureComponent<Props & FormikProps<Values>, State> {
  private bodyClassObserver?: MutationObserver;
  private revealables: {
    [K in keyof State["isRevealed"]]: { label: string; ref: React.RefObject<HTMLInputElement> };
  } = {
    dates: { label: "Date", ref: React.createRef<HTMLInputElement>() },
    location: { label: "Location", ref: React.createRef<HTMLInputElement>() },
    organization: { label: "Organization", ref: React.createRef<HTMLInputElement>() },
    phone: { label: "Phone", ref: React.createRef<HTMLInputElement>() },
  };

  constructor(props: Props & FormikProps<Values>) {
    super(props);

    this.state = {
      isRevealed: { location: true, dates: false, organization: false, phone: true },
      isUnavailable: { organization: false },
    };
  }

  public componentDidMount() {
    const detectVariant = ({ classList }: HTMLElement): boolean => {
      if (classList.contains("contact-form-organization-revealed")) {
        this.setState(({ isRevealed }) => ({
          isRevealed: { ...isRevealed, organization: true },
        }));

        return true;
      }

      if (classList.contains("contact-form-organization-unavailable")) {
        this.setState(({ isUnavailable }) => ({
          isUnavailable: { ...isUnavailable, organization: true },
        }));

        return true;
      }

      return false;
    };

    if (!detectVariant(document.body)) {
      this.bodyClassObserver = new MutationObserver((mutations, observer) =>
        mutations.forEach(({ target }) => {
          if (target instanceof HTMLElement) {
            if (detectVariant(target)) {
              observer.disconnect();
            }
          }
        }),
      );

      this.bodyClassObserver.observe(document.body, {
        attributeFilter: ["class"],
        attributes: true,
      });
    }
  }

  public render() {
    const {
      handleChange,
      initialValues,
      isSubmitting,
      layout,
      messageInputRef,
      placeholder,
      values,
    } = this.props;
    const { isRevealed, isUnavailable } = this.state;

    const is2019 = layout === "2019";
    const revealableButtons = Object.entries(isRevealed)
      .filter(
        ([name, fieldIsRevealed]) =>
          !isUnavailable[name as keyof typeof isUnavailable] &&
          !fieldIsRevealed &&
          this.useField(initialValues, name as keyof typeof isRevealed),
      )
      .map(([name, _], index) => (
        <React.Fragment key={name}>
          {index === 0 ? null : " "}
          <button
            className={is2019 ? "label subtle" : "label secondary"}
            onClick={() => this.reveal(name as keyof typeof isRevealed)}
            type="button"
          >
            <span className="fas fa-plus" />{" "}
            {this.revealables[name as keyof typeof isRevealed].label}
          </button>
        </React.Fragment>
      ));

    return (
      <Form>
        <fieldset disabled={isSubmitting}>
          <FastField name="context" type="hidden" />
          <FastField name="prompt" type="hidden" />

          <div className={is2019 ? "grid-x grid-margin-x" : "row"}>
            <div
              className={
                is2019
                  ? "cell medium-auto contact-form-main"
                  : "column small-12 large-7 whole-medium-6 whole-large-7 contact-form-main"
              }
            >
              <label>
                <span className="field-label">{values.prompt}</span>
                <Field
                  className="hollow"
                  component="textarea"
                  innerRef={messageInputRef}
                  name="message"
                  placeholder={placeholder}
                  required={true}
                  rows={4}
                />
              </label>
            </div>

            <div
              className={
                is2019 ? "cell medium-4" : "column small-12 large-5 whole-medium-6 whole-large-5"
              }
            >
              {isRevealed.location && this.useField(initialValues, "location") && (
                <div className="revealed-field">
                  <label>
                    <span className="field-label">Event Location</span>
                    <FastField
                      className="hollow"
                      innerRef={this.revealables.location.ref}
                      name="location"
                      placeholder="Seattle, WA"
                      required={true}
                      type="text"
                    />
                  </label>
                </div>
              )}

              {isRevealed.dates && this.useField(initialValues, "dates") && (
                <div className="revealed-field">
                  <label>
                    <span className="field-label">Event Dates</span>
                    <FastField
                      className="hollow"
                      innerRef={this.revealables.dates.ref}
                      name="dates"
                      placeholder="5/21, 6/14 - 6/18"
                      type="text"
                    />
                  </label>
                  <React.Suspense fallback={null}>
                    <MultiDatePreview is2019={is2019} value={values.dates} />
                  </React.Suspense>
                </div>
              )}

              {!isUnavailable.organization &&
                isRevealed.organization &&
                this.useField(initialValues, "organization") && (
                  <div className="revealed-field">
                    <label>
                      <span className="field-label">Organization</span>
                      <FastField
                        className="hollow"
                        innerRef={this.revealables.organization.ref}
                        name="organization"
                        placeholder="My Organization"
                        type="text"
                      />
                    </label>
                  </div>
                )}

              <div className={is2019 ? "grid-x grid-margin-x" : "row"}>
                <div className={is2019 ? "cell small-6 medium-12" : "column small-6 medium-12"}>
                  <label>
                    <span className="field-label">Name</span>
                    <FastField
                      className="hollow"
                      name="name"
                      onChange={this.onChangeName(handleChange)}
                      pattern=".*[^\s]+\s+[^\s]+.*"
                      placeholder="Full Name"
                      required={true}
                      type="text"
                    />
                  </label>
                </div>

                <div className={is2019 ? "cell small-6 medium-12" : "column small-6 medium-12"}>
                  <label>
                    <span className="field-label">Email</span>
                    <FastField
                      className="hollow"
                      name="email"
                      placeholder="me@example.com"
                      required={true}
                      type="email"
                    />
                  </label>
                </div>
              </div>

              {isRevealed.phone && this.useField(initialValues, "phone") && (
                <div className="revealed-field">
                  <label>
                    <span className="field-label">Phone</span>
                    <FastField
                      className="hollow"
                      innerRef={this.revealables.phone.ref}
                      name="phone"
                      placeholder="555-555-5555"
                      required={true}
                      type="tel"
                    />
                  </label>
                </div>
              )}

              {revealableButtons.length > 0 && (
                <>
                  <label className="field-label">More Details</label>
                  <p className="revealable-field-links">{revealableButtons}</p>
                </>
              )}
            </div>
          </div>

          <div className={is2019 ? "grid-x grid-margin-x align-middle" : "row align-middle"}>
            {this.useField(initialValues, "subscribe") && (
              <div
                className={
                  is2019 ? "cell medium-auto" : "column small-12 whole-medium-expand large-expand"
                }
              >
                <p className={is2019 ? "medium-no-margin-bottom" : undefined}>
                  <span className={is2019 ? "text-muted" : "with-button muted"}>
                    <label className={is2019 ? "label-plain" : "plain"}>
                      <FastField
                        className={is2019 ? "no-margin-bottom" : undefined}
                        defaultChecked={initialValues.subscribe}
                        name="subscribe"
                        type="checkbox"
                      />
                      Email me updates about event planning resources.
                    </label>
                  </span>
                </p>
              </div>
            )}

            {is2019 ? (
              <div className="cell medium-shrink">
                <div className="grid-x align-middle">
                  <div className="cell auto text-right text-muted">
                    <p className="no-margin-bottom">
                      Call{" "}
                      <a href="tel:+1-855-509-7767" tabIndex={-1}>
                        855-509-7767
                      </a>{" "}
                      or&nbsp;
                    </p>
                  </div>
                  <div className="cell shrink">
                    <button
                      className="button no-margin-bottom"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`column small-12 text-right${
                  this.useField(initialValues, "subscribe")
                    ? " large-shrink whole-medium-shrink"
                    : ""
                }`}
              >
                <p>
                  <span className="with-button muted">
                    Call{" "}
                    <a href="tel:+1-855-509-7767" tabIndex={-1}>
                      855-509-7767
                    </a>{" "}
                    or{" "}
                  </span>
                  <button className="button" disabled={isSubmitting} type="submit">
                    Send
                  </button>
                </p>
              </div>
            )}
          </div>
        </fieldset>
      </Form>
    );
  }

  private onChangeName(callback: React.EventHandler<React.ChangeEvent<HTMLInputElement>>) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.setCustomValidity(
        event.target.validity.patternMismatch ? "Please enter a first and last name." : "",
      );

      callback(event);
    };
  }

  private reveal(field: keyof State["isRevealed"]) {
    const suggestion = this.props.suggestions && this.props.suggestions[field];
    if (suggestion) {
      this.props.setFieldValue(field, suggestion);
    }

    this.setState(({ isRevealed }) => ({ isRevealed: { ...isRevealed, [field]: true } }));

    setTimeout(() => {
      const input = this.revealables[field].ref.current;

      if (input) {
        input.focus();
      }
    }, 0);
  }

  private useField(initialValues: Values, name: keyof Values): boolean {
    return initialValues[name] !== undefined;
  }
}

export default withFormik<Props, Values>({
  handleSubmit: (
    values,
    { props: { abort, gtag, raven, scrollIntoViewIfNeeded, setSubmitted }, setSubmitting },
  ) => {
    values.inquiryId = getInquiryId(raven);
    if (document.body.classList.contains("receipt-variant_time-estimate")) {
      values.receiptVariant = "time-estimate";
    }

    raven.captureBreadcrumb({ category: "ContactForm", message: "submit", data: { values } });

    const isOrganization =
      hasText(values.organization) &&
      !/^(?:home|n\/?a|none|private|self)$/i.test(values.organization.trim());
    reportAnalytics(raven, gtag, isOrganization);

    fetch(endpoint, {
      body: JSON.stringify(values),
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      method: "POST",
      mode: "cors",
    })
      .then(() => {
        setSubmitted();
        setSubmitting(false);
      })
      .catch((error) => abort(error))
      .then(() => setTimeout(() => scrollIntoViewIfNeeded(), 0));
  },
  mapPropsToValues: ({ context, event, minimal, prompt }: Props): Values => ({
    context: context ?? window.location.href,
    email: "",
    message: "",
    name: "",
    prompt,
    ...(minimal
      ? {}
      : {
          phone: "",
          ...(event ? { dates: "", location: "", organization: "", subscribe: false } : {}),
        }),
  }),
})(ContactFormInner);
