import type { LazySizesConfigPartial } from "lazysizes";

const windowWithConfig = window as Window & Partial<{ lazySizesConfig: LazySizesConfigPartial }>;
windowWithConfig.lazySizesConfig = windowWithConfig.lazySizesConfig ?? {};
windowWithConfig.lazySizesConfig.autosizesClass = "autosizes";

import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import lazySizes from "lazysizes";

import balanceText from "balance-text";
import $ from "jquery";
import Raven from "raven-js";
import * as React from "react";
import * as ReactDOM from "react-dom";
import ContactForm from "./components/ContactForm";
import { addEventListeners, always, isPlayCanceled, newOnReadyState } from "./lib/utilities";

const Catalog = React.lazy(() => import("./components/Catalog"));

(window as any).jQuery = $;
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.core.min.js");
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.util.imageLoader.min.js");
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js");
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.util.motion.min.js");
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.util.timer.min.js");
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.util.touch.min.js");
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.accordion.min.js");
/* tslint:disable-next-line:no-var-requires */
require("foundation-sites/dist/js/plugins/foundation.orbit.min.js");
declare const Foundation: any;

//
// Externals
//

const gtag = (window as Window & Partial<{ gtag: Gtag }>).gtag;

//
// Sentry
//

const isSupportedBrowser =
  typeof Object.defineProperty === "function" &&
  typeof window.console === "function" &&
  !/test|wkhtmltoimage/.test(navigator.userAgent);

Raven.config("https://06825940ca0b4f8da5840b18b93bc640@sentry.io/119503", {
  ignoreErrors: [
    // Embedded media
    /blocked a frame.*youtube\.com/i,
  ],
  ignoreUrls: [
    // Bots
    /nerdybot/i,

    // Development
    /localhost/i,
    /local\.host/i,
  ],
  shouldSendCallback: always(isSupportedBrowser),
}).install();

//
// Main
//

const onReadyState = newOnReadyState({
  complete: () => {
    console.log("Complete");

    //
    // Aside overflow items
    //

    document.querySelectorAll<HTMLElement>(".aside").forEach((aside) => {
      const canary = aside.querySelector<HTMLElement>(".aside-unused-space");
      const fillerItems = [...aside.querySelectorAll<HTMLElement>(".aside-overflow")].reverse();

      if (canary) {
        const fill = () => {
          const item = fillerItems.pop();

          if (item && canary.offsetHeight > 150) {
            item.classList.remove("aside-overflow");

            window.requestAnimationFrame(fill);
          }
        };

        window.requestAnimationFrame(fill);
      }
    });
  },
  interactive: () => {
    console.log("Interactive");

    //
    // Balance text
    //

    console.log("Scheduling balancing of text");
    balanceText();

    //
    // Actual viewport height
    //

    const minHeight100vhElements = document.querySelectorAll<HTMLElement>(".subsite-header-large");

    // Note: Initial set is inline.
    window.addEventListener("resize", () =>
      minHeight100vhElements.forEach((element) => {
        console.log("Applying actual viewport height");
        element.style.minHeight = `${window.innerHeight}px`;
      }),
    );

    //
    // Actual image sizes
    //

    console.log("Refining image display sizes.");
    lazySizes.autoSizer?.checkElems();

    //
    // Text folding
    //

    document.querySelectorAll<HTMLElement>(".small-only-fold").forEach((container) => {
      const onClick = () => {
        container.removeEventListener("click", onClick);
        container.classList.remove("collapsed");
      };

      container.addEventListener("click", onClick);
      container.classList.add("collapsed");
    });

    //
    // Foundation
    //

    console.log("Initializing Foundation");
    document
      .querySelectorAll<HTMLElement>(".accordion")
      .forEach((container) => new Foundation.Accordion($(container)));
    document
      .querySelectorAll<HTMLElement>(".orbit")
      .forEach((container) => new Foundation.Orbit($(container)));

    //
    // Smooth scrolling to anchors
    //

    const smoothScrollHandler = (event: MouseEvent | React.MouseEvent<HTMLAnchorElement>): void => {
      if (event.target instanceof HTMLAnchorElement) {
        const subject = document.querySelector<HTMLElement>(event.target.hash);

        if (subject) {
          event.preventDefault();

          subject.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
      }
    };

    console.log("Initializing smooth scrolling to anchors");
    document
      .querySelectorAll<HTMLAnchorElement>("a[href^='#']:not([role='tab'])")
      .forEach((a) => a.addEventListener("click", smoothScrollHandler));

    //
    // Catalog
    //

    const catalog = document.getElementById("catalog");
    if (catalog) {
      console.log("Loading catalog");

      const initialCategoryId =
        (catalog.dataset.categoryId && Number.parseInt(catalog.dataset.categoryId, 10)) ||
        undefined;
      const scope =
        (catalog.dataset.scope && Number.parseInt(catalog.dataset.scope, 10)) || undefined;

      // Early height restoration to allow native scroll restoration
      const key = "catalogHeight";
      const height = window.sessionStorage.getItem(key);
      if (typeof height === "string") {
        catalog.style.minHeight = height;
      }
      window.addEventListener("pagehide", () => {
        window.sessionStorage.setItem(key, `${Math.ceil(catalog.offsetHeight)}px`);
      });
      const handleLoad = () => {
        requestAnimationFrame(() => catalog.style.removeProperty("min-height"));
        window.sessionStorage.removeItem(key);
      };

      try {
        ReactDOM.render(
          <React.Suspense fallback={null}>
            <Catalog
              gtag={gtag}
              initialCategoryId={initialCategoryId}
              onLoad={handleLoad}
              raven={Raven}
              scope={scope}
            />
          </React.Suspense>,
          catalog,
        );
      } catch (error) {
        document.body.classList.add("error");
        Raven.captureException(error);
      }
    }

    //
    // Forms
    //

    const filledPattern = /.*\w.*/;

    // Required textareas
    const requiredTextareaChangeHandler: EventListener = ({ target }) =>
      target instanceof HTMLTextAreaElement &&
      target.setCustomValidity(
        filledPattern.test(target.value) ? "" : "Please fill out this field.",
      );
    document
      .querySelectorAll<HTMLTextAreaElement>("textarea[required]")
      .forEach((textarea) =>
        addEventListeners(textarea, ["change", "input"], requiredTextareaChangeHandler),
      );

    // Conditionally required fields
    document.querySelectorAll<HTMLInputElement>("input[data-require-if]").forEach((subject) =>
      document
        .querySelectorAll<HTMLInputElement>(`input[name=${subject.dataset.requireIf}]`)
        .forEach((reference) => {
          addEventListeners(reference, ["change", "input"], ({ target }) => {
            if (target instanceof HTMLInputElement) {
              subject.required = filledPattern.test(target.value);
            }
          });
        }),
    );
    document.querySelectorAll<HTMLInputElement>("input[data-require-unless]").forEach((subject) =>
      document
        .querySelectorAll<HTMLInputElement>(`input[name=${subject.dataset.requireUnless}]`)
        .forEach((reference) => {
          addEventListeners(reference, ["change", "input"], ({ target }) => {
            if (target instanceof HTMLInputElement) {
              subject.required = !filledPattern.test(target.value);
            }
          });
        }),
    );

    // Custom validation error messages
    document
      .querySelectorAll<HTMLInputElement>("input[pattern][data-pattern-instruction]")
      .forEach((input) => {
        const instruction = input.dataset.patternInstruction;

        if (instruction) {
          addEventListeners(
            input,
            ["change", "input"],
            ({ target }) =>
              target instanceof HTMLInputElement &&
              target.setCustomValidity(target.validity.patternMismatch ? instruction : ""),
          );
        }
      });

    // Careers contact form
    const careersFormSubmitHandler: EventListener = ({ target }) => {
      if (!(target instanceof HTMLFormElement)) {
        return true;
      }
      console.log("Careers form was submitted");

      // Manually invoke validation (Safari < 10.1)
      console.log("Manually invoking form validation");
      const invalidField = [
        ...target.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>("input, textarea"),
      ].find((element) => !element.checkValidity());
      if (invalidField) {
        invalidField.focus();
        return false;
      }

      // Discourage double submission
      console.log("Protecting against double submission");
      if (target.dataset.submitted === "true") {
        return false;
      }
      target.dataset.submitted = "true";
      target.querySelectorAll("button").forEach((button) => {
        button.disabled = true;
      });

      // Submit
      console.log("Permitting submission");
      return true;
    };
    document
      .querySelectorAll<HTMLFormElement>("form.careers-contact-form")
      .forEach((form) => form.addEventListener("submit", careersFormSubmitHandler));

    // Disable on submit
    const formDisableOnSubmitHandler: EventListener = ({ target }) => {
      if (target instanceof HTMLFormElement) {
        target.querySelectorAll("button").forEach((button) => {
          button.disabled = true;
        });
        target
          .querySelectorAll<HTMLInputElement | HTMLTextAreaElement>("input, textarea")
          .forEach((field) => {
            field.readOnly = true;
          });
      }

      return true;
    };
    document
      .querySelectorAll<HTMLFormElement>("form[data-disable-on-submit]")
      .forEach((form) => form.addEventListener("submit", formDisableOnSubmitHandler));

    // Google Analytics events
    document.querySelectorAll<HTMLFormElement>("form[data-ga-event-action]").forEach((form) => {
      const { gaEventAction, gaEventCategory } = form.dataset;

      form.addEventListener("submit", () => {
        try {
          if (gtag && document.location) {
            console.log("Emitting Google Analytics beacon");
            gtag("event", gaEventAction, {
              event_category: gaEventCategory,
              transport_type: "beacon",
            });
          }
        } catch (error) {
          console.log("Ignoring error", error);
          Raven.captureException(error);
        }
      });
    });

    //
    // Incremental contact form
    //

    Array.from(document.querySelectorAll<HTMLDivElement>(".incremental-contact-form")).forEach(
      (container) => {
        const data: Record<string, unknown> = {
          event: container.dataset.event && JSON.parse(container.dataset.event),
          layout: container.dataset.layout && JSON.parse(container.dataset.layout),
          minimal: container.dataset.minimal && JSON.parse(container.dataset.minimal),
          placeholder: container.dataset.placeholder && JSON.parse(container.dataset.placeholder),
          prompt: container.dataset.prompt && JSON.parse(container.dataset.prompt),
          suggestedLocation:
            container.dataset.suggestedLocation && JSON.parse(container.dataset.suggestedLocation),
        };

        const event = typeof data.event === "boolean" ? data.event : undefined;
        const layout = data.layout === "2016" || data.layout === "2019" ? data.layout : "2016";
        const minimal = typeof data.minimal === "boolean" ? data.minimal : undefined;
        const placeholder = typeof data.placeholder === "string" ? data.placeholder : undefined;
        const prompt = typeof data.prompt === "string" ? data.prompt : undefined;
        const suggestedLocation =
          typeof data.suggestedLocation === "string" ? data.suggestedLocation : undefined;

        ReactDOM.render(
          <ContactForm
            event={event}
            gtag={gtag}
            layout={layout}
            minimal={minimal}
            placeholder={placeholder}
            prompt={prompt}
            raven={Raven}
            suggestions={{ location: suggestedLocation }}
          />,
          container,
        );
      },
    );

    //
    // Image stack
    //

    document.querySelectorAll<HTMLElement>(".images-layout").forEach((layout) => {
      let activeSlide: HTMLElement;
      let activeVideo: HTMLVideoElement | null = null;

      layout.querySelectorAll<HTMLElement>(".images-layout-nav-item").forEach((navItem) => {
        const id = navItem.dataset.id;

        if (id) {
          const slide = layout.querySelector<HTMLElement>(
            `.images-layout-main-content[data-id="${id}"]`,
          );

          if (slide) {
            const video = slide.querySelector("video");

            if (slide.classList.contains("active")) {
              activeSlide = slide;
              activeVideo = video;
            }

            navItem.addEventListener("mouseenter", () => {
              if (slide !== activeSlide) {
                slide.classList.add("active");
                activeSlide.classList.remove("active");

                if (activeVideo) {
                  activeVideo.pause();
                }
                if (video) {
                  try {
                    video.play();
                  } catch (error) {
                    if (!isPlayCanceled(error)) throw error;
                  }
                }

                activeSlide = slide;
                activeVideo = video;
              }
            });
          }
        }
      });
    });

    //
    // Orbit
    //

    // Hover to select
    document
      .querySelectorAll<HTMLButtonElement>(".orbit-image-bullets button")
      .forEach((button) =>
        button.addEventListener(
          "mouseenter",
          ({ target }) => target && target.dispatchEvent(new MouseEvent("click")),
        ),
      );

    document.querySelectorAll<HTMLElement>(".orbit").forEach((orbit) => {
      const downloadButton = orbit.querySelector<HTMLAnchorElement>("a.orbit-download");

      $(orbit).on("beforeslidechange.zf.orbit", (_, $from: JQuery, $to: JQuery) => {
        const from = $from[0];
        const to = $to[0];

        // Apply placeholder background image.
        orbit.style.backgroundImage = to.dataset.placeholder ? to.dataset.placeholder : "none";

        // Configure download button.
        if (downloadButton) {
          const downloadName = to.dataset.downloadName;
          const downloadUrl = to.dataset.downloadUrl;

          if (downloadName && downloadUrl) {
            downloadButton.download = downloadName;
            downloadButton.href = downloadUrl;
            downloadButton.classList.remove("hide");
          } else {
            downloadButton.classList.add("hide");
          }
        }

        // Play or pause video.
        const fromVideo = from.querySelector("video");
        if (fromVideo) {
          fromVideo.pause();
        }
        const toVideo = to.querySelector("video");
        if (toVideo) {
          try {
            toVideo.play();
          } catch (error) {
            if (!isPlayCanceled(error)) throw error;
          }
        }
      });
    });

    //
    // AdWords conversions
    //

    if (gtag) {
      document.querySelectorAll<HTMLAnchorElement>('a[href="tel:+1-855-509-7767"]').forEach((a) =>
        a.addEventListener("click", () => {
          gtag("event", "conversion", {
            send_to: "AW-987727095/Y-cVCIv7hH8Q94n-1gM",
            transport_type: "beacon",
          });

          return true;
        }),
      );
    }
  },
  loading: () => undefined,
});

Raven.context(() => {
  document.addEventListener(
    "readystatechange",
    ({ target }) => target instanceof Document && onReadyState(target.readyState),
  );

  onReadyState(document.readyState);
});
